import Image from 'global/Image';
import useDownloadDesktopApp from 'hooks/useDownloadDesktopApp';
import { DownloadButtonsContainer } from './styled';
import { DownloadSDASource } from './constants';
import { DesktopAppDownloadAnalytics } from './desktop-analytics';

const DownloadButtons = ({ source }: { source: DownloadSDASource }) => {
  const { download } = useDownloadDesktopApp();

  return (
    <DownloadButtonsContainer>
      <div className="btn-container">
        <Image
          onClick={() => {
            download('macos');
            DesktopAppDownloadAnalytics.onDownloadDesktopApp({
              os: 'Mac',
              source,
            });
          }}
          src={`/images/download-macos${
            source === 'Stockbit Main Page' ? '-light' : ''
          }.svg`}
          alt="Download untuk MacOs"
          className="download-button"
        />
        <span>MacOS 12 or above</span>
      </div>
      <div className="btn-container" style={{ position: 'relative' }}>
        <Image
          src={`/images/download-windows${
            source === 'Stockbit Main Page' ? '-light' : ''
          }.svg`}
          alt="Download untuk Windows"
          className="download-button"
          onClick={() => {
            download('windows');
            DesktopAppDownloadAnalytics.onDownloadDesktopApp({
              os: 'Windows',
              source,
            });
          }}
        />
        <span>Windows 10 or above</span>
      </div>
    </DownloadButtonsContainer>
  );
};

export default DownloadButtons;
