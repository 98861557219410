import styled from 'styled-components';

export const ImgWrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  position: relative;
  display: flex;
  width: 100%;

  cursor: pointer;

  & > img {
    width: 100%;
    border-radius: 8px;
  }
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;

  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 64.58%, #141414 100%);
  transition: opacity 0.15s ease-out;

  &:hover {
    opacity: 1;
  }
`;

export const UsernameWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
  z-index: 2;

  @media screen and (min-width: 768px) {
    padding: 8px;
  }
`;

export const LinkWrapper = styled.a`
  display: flex;
`;
