import Image from 'global/Image';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const UserAvatar = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  object-fit: cover;

  @media screen and (min-width: 768px) {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
`;

export const NameText = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  color: #ffffff;
`;
