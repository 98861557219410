const useDownloadDesktopApp = () => {
  const getUrl = (os: 'macos' | 'windows') => {
    const path = os === 'macos' ? 'macos/Stockbit.dmg' : 'windows/Stockbit.msi';
    const url = `${process.env.NEXT_PUBLIC_DESKTOP_RELEASE_URL}/release/${path}`;

    return url;
  };

  const download = (os: 'macos' | 'windows') => window.open(getUrl(os), '_blank');

  return {
    download,
    getUrl,
  };
};

export default useDownloadDesktopApp;
