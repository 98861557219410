import { Button, Row } from 'antd';
import BaseImageItem from 'features/tradingsetup/components/ImageItem';
import styled from 'styled-components';

export const Container = styled.section`
  padding: 30px 0;
  margin: 100px auto;
  height: 100%;

  .fade {
    box-shadow: 0px -90px 250px 72px rgba(0, 171, 107, 0.75);
    margin: 0 270px;
  }
  .separator-wrapper {
    display: flex;
    justify-content: center;
    width: 100vw;
  }
  .separator {
    width: 80vw;
    height: 2px;
    background: radial-gradient(
      circle farthest-corner at center center,
      #00ab6b,
      transparent
    );
  }
  @media (min-width: 1080px) {
    margin-top: 180px;
  }
  @media (max-width: 700px) {
    margin-top: 66px;
    padding: 50px 0;
    .fade {
      margin: 0 30px;
    }
  }
`;

export const Hero = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 110vh;
  opacity: 1;

  @media (min-width: 1080px) {
    min-height: unset;
  }
  @media (max-width: 767px) {
    .image,
    .video-hero {
      top: -100% !important;
    }
  }
`;

export const ImageWithShadow = styled.div<{
  marginTop?: number;
  substraction?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 877px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  .ellipse {
    transform: rotate(180deg);
    opacity: 0.8;
  }
  &.image-multi {
    height: 750px;
  }
  .image {
    position: relative;
    top: -584px;
    width: 80vw;
    max-width: 1000px;
    &.video-multi-monitor,
    &.multi-monitor {
      width: unset;
      max-width: unset;
    }
  }

  &.image-hero {
    .ellipse {
      position: relative;
      top: 0px;
    }
    .image {
      top: -660px;
    }
    .video-hero {
      z-index: 1;
      position: absolute;
      left: 0;
      transform: scale(0.98);
      top: -656px;
    }
  }

  &.image-value-mobile {
    display: none;
  }

  @media (max-width: 820px) {
    &.image-value-desktop {
      display: none;
    }
    &.image-value-mobile {
      display: flex;
    }
    .video-hero,
    .hero-bezel {
      top: -415px !important;
    }
  }

  @media (max-width: 768px) {
    height: 320px;
    margin-top: 40px;
    &.image-multi {
      height: 150px;
      .ellipse {
        top: 5px;
      }
    }
    &.image-hero {
      .ellipse {
        top: 15px;
      }
    }
    .image {
      top: -300px;

      &.video-multi-monitor,
      &.multi-monitor {
        width: 80vw;
        max-width: 1000px;
      }
    }
    .ellipse {
      width: 100%;
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    .image {
      top: -190px !important;
      width: 310px !important;
    }

    height: 320px;
  }
  @media only screen and (max-width: 767px) and (min-width: 536px) {
    height: unset;
    &.image-value {
      margin-top: 0;
      .image {
        top: -200px !important;
      }
    }
  }
  @media only screen and (max-width: 728px) and (min-width: 498px) {
    .video-hero,
    .hero-bezel {
      top: -222px !important;
    }
  }
  @media only screen and (max-width: 820px) and (min-width: 820px) {
    .video-hero,
    .hero-bezel {
      top: -555px !important;
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 536px) {
    height: unset;
    .multi-monitor {
      top: -200px !important;
    }
  }
`;

export const Title = styled.div`
  font-weight: 700;
  display: block;
  text-align: center;

  font-size: 48px;
  line-height: 58px;
  .green {
    color: #0BA16B;
  }
  p {
    margin-bottom: 0;
  }

  &.duplicate {
    position: absolute;
    top: 0;
    zIndex: 100,
    opacity: 0
  }

  @media (max-width: 1080px) {
    font-size: 46px !important;
    line-height: 50px !important;
  }
  @media (max-width: 700px) {
    font-size: 36px !important;
    line-height: 40px !important;
  }
`;

export const HeroTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  ${Title} {
    line-height: 68px;
    font-size: 66px;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  @media (max-width: 700px) {
    width: 85vw;
    ${Title} {
      font-size: 40px;
      line-height: 41px;
      flex-direction: column;
    }
  }
`;

export const SubTitle = styled.p`
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  width: 505px;
  @media (max-width: 700px) {
    width: unset;
    font-size: 18px;
  }
`;

export const DownloadButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
  cursor: pointer;

  .soon {
    cursor: default;
  }

  img {
    bakcground: #141414;
    &.loading {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  @media (max-width: 700px) {
    img {
      width: 160px;
    }
  }
  position: relative;
  z-index: 1;

  .btn-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    span {
      color: #cbcbcb;
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const Value = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  padding: 0 270px;
  .image-example {
    width: 80vw;
    max-width: 1000px;
  }
  @media (max-width: 700px) {
    padding: 0 30px;
    margin-top: 0;
    gap: 40px;
    ${Title} {
      font-size: 36px;
      line-height: 40px;
    }
  }
  @media (max-width: 768px) {
    padding: 0 30px;
  }
  @media (max-width: 1024px) {
    padding: 0 30px;
  }
  @media (min-width: 1024px) {
    min-height: unset;
  }
`;

export const ValueMultiMonitor = styled(Value)`
  margin-top: -10px;
  ${Title} {
    max-width: 902.19px;
  }
  @media (max-width: 700px) {
    margin-top: -120px;
  }
`;

export const ValueCustomLayout = styled(Value)`
  .images-wrapper {
    position: relative;
    .image {
      width: 80vw;
      max-width: 1000px;
    }
    video {
      position: absolute;
      left: 0;
      transform: scale(0.98, 0.98);
      top: 6px;
    }
  }
  // for when advanced orderbook not ready
  position: relative;
  z-index: 1;
  background: #141414;
  padding-bottom: 228px;
  // for when advanced orderbook not ready

  @media (max-width: 700px) {
    margin-top: -120px;
    padding-bottom: 60px;
    .image {
      width: 100%;
    }
    video {
      top: 0px;
    }
  }
`;

export const ValuePoints = styled.div<{ gap?: number }>`
  display: flex;
  gap: ${({ gap = 32 }) => `${gap}px`};
  @media (max-width: 700px) {
    &.value-points-icon {
      flex-direction: row;
    }
    &.value-points {
      gap: 24px;
    }
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    &.value-points {
      gap: 24px;
      flex-direction: column;
    }
  }
`;

export const ValuePointIconItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 168px;
  align-items: center;
  span {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
  }
  img {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 700px) {
    width: unset;
    gap: 10px;
    span {
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const ValuePointItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21.37px;

  width: 315px;

  .text {
    font-size: 32px;
    line-height: 38.98px;
    font-weight: 700;
  }
  .subtext {
    font-size: 18px;
    line-height: 30px;
  }

  @media (max-width: 700px) {
    padding: 0 30px;

    .text {
      font-size: 24px;
      line-height: 29.23px;
    }

    .subtext {
      font-size: 16px;
      line-height: 26px;
    }

    gap: 8px;
  }
  @media (max-width: 768px) {
    padding: 0 30px;
    width: unset;
    &:nth-child(1) {
      width: unset;
    }

    .text {
      font-size: 24px;
      line-height: 29.23px;
    }

    .subtext {
      font-size: 16px;
      line-height: 26px;
    }

    gap: 8px;
  }
  @media (max-width: 1024px) {
    width: unset !important;
  }
`;

export const AdvancedOrderbook = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 358px;
  position: relative;
  z-index: 2;
  background: #141414;
  padding-bottom: 193px;
  margin-top: 364px;
  @media (max-width: 700px) {
    flex-direction: column;
    padding-bottom: 80px;
    > div {
      width: 80vw !important;
      max-width: 1000px;
    }
  }
  @media (max-width: 412px) {
    display: none;
  }
`;

export const AdvancedOrderbookRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 542px;
  ${Title} {
    text-align: start;
  }
`;

export const CollapsibleHighlightedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 250px;
`;
export const CollapsibleHighlightedItem = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .title {
    cursor: pointer;
    color: ${({ active }) => (active ? '#00AB6B' : '#FFFFFF60')};
    font-size: 22px;
    font-weight: 700;
    line-height: 26.8px;
  }
  .content {
    font-size: 18px;
    line-height: 30px;
    max-height: 0px;
    overflow: hidden;
    max-height: ${({ active }) => (active ? '200px' : 0)};
    transition: max-height ${({ active }) => (active ? '0.8s' : '0.4s')}
      ${({ active }) => (active ? 'ease-in-out' : 'ease-out')};
  }
`;

export const FAQContainer = styled.div`
  padding-bottom: 137px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .ellipse {
    z-index: 1;
    height: 877px;
    max-width: 1300px;
    width: 80%;
  }
  ${Title} {
    margin-bottom: 64px;
    margin-top: 122px;
  }

  @media (max-width: 700px) {
    .ellipse {
      height: 400px;
      width: 98vw;
    }
    ${Title} {
      padding: 0 50px;
    }
    padding-bottom: 68px;
  }
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 800px;
  position: relative;
  z-index: 1;
  @media (max-width: 960px) {
    width: unset;
    gap: 12px;
    padding: 0 20px;
  }
`;

export const AccordionItemWrapper = styled.div<{ active: boolean }>`
  display: flex;
  gap: 8px;
  padding: 24px;
  background-color: #171717;
  align-items: center;
  cursor: pointer;

  .chevron {
    transform: rotate(${({ active }) => (active ? '180' : '0')}deg);
    transition: all 0.5s ease-in-out;
  }
  border-radius: 8px;
  @media (max-width: 768px) {
    border: 1px solid #2e2e2e;
    .chevron {
      width: 24px;
      height: 24px;
    }
  }
`;
export const AccordionItemContainer = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .collapsible {
    background-color: #171717;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .content {
    max-height: 0;
    overflow: hidden;
    font-size: 18px;
    line-height: 26px;
    &.active {
      max-height: 500px;
      transition: max-height 0.8s ease-in-out;
    }
    &.inactive {
      max-height: 0px;
      transition: max-height 0.3s ease-out;
    }
  }
  @media (max-width: 768px) {
    .collapsible {
      font-size: 18px;
      line-height: 26px;
    }
    .content {
      font-size: 16px;
      line-height: 26px;
    }
  }
`;

export const DownloadSection = styled.div`
  display: flex;
  gap: 45px;
  justify-content: center;
  align-items: center;
  .image {
    width: 638px;
    height: 358px;
  }
  .divider {
    height: 289px;
    width: 1px;
    background: #2e2e2e;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    .image {
      width: 100%;
      padding: 0 30px;
    }
  }

  @media (max-width: 768px) {
    .image {
      height: 178px;
    }
    .desktop-laptop {
      height: 100%;
    }
  }
  @media (max-width: 1080px) {
    .divider {
      display: none;
    }
  }
`;

export const DownloadSectionLeft = styled.div`
  flex-direction: column;
  display: flex;
  gap: 40px;
  width: 501px;
  ${Title} {
    font-size: 48px;
    line-height: 58px;
    text-align: start;
  }

  @media (max-width: 700px) {
    width: unset;
    padding: 0 12px;
    text-align: center;
    gap: 20px;
    align-items: center;
    ${Title} {
      text-align: center;
    }
  }
`;

export const MobileAdvancedOrderbook = styled.div`
  margin: 0 20px;
  position: relative;
  z-index: 2;
  background: #141414;
  padding-bottom: 20px;
  display: none;
  @media (max-width: 412px) {
    display: block;
    margin: 0;
    padding-left: 20px;
    ${Title} {
      margin-left: -20px;
      margin-top: 60px;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #2e2e2e;
  border-radius: 8px;
  padding: 24px;
  margin-top: 40px;
  .title {
    margin-top: 16px;
    font-size: 18px;
    line-height: 26px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  overflow: auto;
  gap: 40px;
  width: 90vw;
  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
`;

export const CardIndicatorContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;

export const Dot = styled.div<{ active: boolean }>`
  margin-top: 24px;
  width: 8px;
  height: 8px;
  background: ${({ active }) => (active ? '#00AB6B' : '#595959')};
  border-radius: 100%;
  cursor: pointer;
`;

export const VideoMultiMonitor = styled.video<{
  right?: number;
  left?: number;
  marginTop?: number;
  width: number;
}>`
  position: absolute;
  z-index: 2;
  ${({ left }) => left && `left: ${left}px;`}
  ${({ right }) => right && `right: ${right}px;`}
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};
  width: ${({ width }) => `${width / 2 - 19}px !important`};
  top: -584px;
  @media (max-width: 768px) {
    top: -300px;
    width: 80vw;
  }

  @media (max-width: 767px) {
    top: -190px !important;
    width: ${({ width }) => `${width / 2 - 8}px !important`};
    ${({ left }) => left && 'left: 2px;'}
    margin-top: 0px;
    ${({ right }) => right && 'right: 2px;'}
  }
  @media only screen and (max-width: 680px) and (min-width: 536px) {
    top: -200px !important;
    margin-top: 0px;
  }
`;

export const SystemRequirementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 501px;
  gap: 20px;

  .title {
    font-size: 22px;
    font-weight: 700;
  }
  .tabs {
    display: flex;
    gap: 32px;
    .tab-head {
      font-size: 18px;
      font-weight: 700;
      cursor: pointer;
      &.active {
        border-bottom: 1px solid #00ab6b;
        color: #00ab6b;
      }
    }
  }
  .tab-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .tab-content-item {
    display: flex;
    gap: 6px;
    font-size: 18px;
    .key {
      color: #cbcbcb;
    }
  }
  @media (max-width: 768px) {
    width: 80vw;
  }
`;

// Share Your Setup Section

export const ShareYourSetupTitle = styled.h1`
  color: #ffffff;
  font-weight: 700;
  display: block;
  text-align: center;

  .green {
    color: #0ba16b;
  }
`;

export const ShareYourSetupSection = styled.section<{
  isCampaignActive?: boolean;
}>`
  position: relative;

  background: #141414;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 64px 0;
  overflow: hidden;

  ${ShareYourSetupTitle} {
    font-size: ${({ isCampaignActive }) =>
      isCampaignActive ? '27px' : '34px'};
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    ${ShareYourSetupTitle} {
      font-size: 48px;
    }
  }

  @media (min-width: 992px) {
    ${ShareYourSetupTitle} {
      font-size: 66px;
      line-height: 68px;
      margin-bottom: ${({ isCampaignActive }) =>
        isCampaignActive ? '40px' : '64px'};
    }
  }
`;

export const ShareSubTitle = styled.p`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;

  @media (min-width: 992px) {
    font-size: 29px;
    line-height: 40px;
    margin-bottom: 40px;
  }
`;

export const ShareImgBody = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;

export const ShareImgContainer = styled.div`
  display: flex;
  height: 416px;
  gap: 28px;

  margin-bottom: 20px;
`;

export const ShareColumnContainer = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width = '100%' }) => width};
  height: 100%;
  gap: 20px;
`;

export const ShareImgWrapper = styled.div<{
  backgroundImage?: string;
  height?: string;
  width?: string;
}>`
  height: ${({ height = '100%' }) => height};
  width: ${({ width = '100%' }) => width};

  background: url(${({ backgroundImage }) => backgroundImage}) no-repeat center;
  background-size: cover;
  border-radius: 18px;
`;

export const SeeGallery = styled(Button).attrs({ type: 'link' })`
  display: flex;
  align-items: center;

  font-size: 20px;
  line-height: 26px;
  font-weight: 700;

  padding: 14px 24px;
  height: auto;

  color: #00ab6b;
`;

export const ImageItem = styled(BaseImageItem)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  & > img {
    height: 100%;
    object-fit: cover;
  }
`;

export const MobileImageList = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  & > div {
    margin-right: 12px;

    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }
  }
`;

export const ImageListRow = styled(Row)`
  width: 100%;
`;

export const TncText = styled.p`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
  color: #0ba16b;
  margin: 0;
`;

export const TncLink = styled.a`
  margin-bottom: 30px;

  @media (min-width: 992px) {
    margin-bottom: 64px;
  }
`;
