import { Col, Row } from 'antd';
import { NameText, UserAvatar } from './styled';

interface UsernameProps {
  userImgSrc?: string;
  username?: string;
}

const Username = ({ userImgSrc = '', username = '' }: UsernameProps) => (
  <Row align="middle" gutter={[8, 0]}>
    <Col>{userImgSrc && <UserAvatar src={userImgSrc} />}</Col>
    <Col>{username && <NameText>@{username}</NameText>}</Col>
  </Row>
);

export default Username;
