import { clevertapEvent } from 'utils/clevertap';
import { DownloadSDASource, SDAOS } from './constants';

export const EVENT = {
  openDesktopAppLandingPage: 'Open Desktop App Landing Page',
  onDownloadDesktopApp: 'Download Desktop App',
};

export const DesktopAppDownloadAnalytics = {
  onDesktopAppLandingPage: () => {
    clevertapEvent(EVENT.openDesktopAppLandingPage);
  },
  onDownloadDesktopApp: ({
    os,
    source,
  }: {
    os: SDAOS;
    source: DownloadSDASource;
  }) => {
    clevertapEvent(EVENT.onDownloadDesktopApp, {
      Source: source,
      OS: os,
    });
  },
};
